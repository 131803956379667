import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],

})
export class InputComponent implements OnInit {

  @Input() value: Observable<number>;
  @Input() valueName: string;

  constructor() {
  }

  ngOnInit() {
  }

}
