import {ChangeDetectionStrategy, Component} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {StoreStateInterface} from '../../core/store/interfaces/store-state.Interface';
import {selectFirstValue, selectSecondValue} from '../../core/store/selectors/basic.selector';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayComponent {

  firstValue = this.store.pipe(select(selectFirstValue));
  secondValue = this.store.pipe(select(selectSecondValue));

  constructor(private readonly store: Store<StoreStateInterface>) {
  }


}
