import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {StoreModule} from '@ngrx/store';
import {basicReducer} from './reducers/basic.reducer';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {BasicEffects} from './effects/basic.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({basic: basicReducer}),
    EffectsModule.forRoot([BasicEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ]
})
export class CoreStoreModule { }
