import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {StoreStateInterface} from './core/store/interfaces/store-state.Interface';
import {selectFirstValue, selectSecondValue} from './core/store/selectors/basic.selector';
import {interval, merge, Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {ChangeAction, ResetAction} from './core/store/actions/basic.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  firstValue = this.store.pipe(select(selectFirstValue));
  secondValue = this.store.pipe(select(selectSecondValue));
  onStartSubject = new Subject();
  onStopSubject = new Subject();
  onResetSubject = new Subject();

  constructor(readonly store: Store<StoreStateInterface>) {
  }

  ngOnInit(): void {
    this.buttonActionsObserver();
  }


  private buttonActionsObserver() {
    merge(
      this.onStartSubject
        .pipe(switchMap(() => this.getOneSecondInterval())))
        .subscribe(() => this.store.dispatch(new ChangeAction()));

    this.onResetSubject
      .pipe(tap(() => this.onStopSubject.next()))
      .subscribe(() => this.store.dispatch(new ResetAction()));
  }


  private getOneSecondInterval() {
    return interval(1000).pipe(takeUntil(this.onStopSubject));
  }


}
