import {StoreStateInterface} from '../interfaces/store-state.Interface';
import {createSelector} from '@ngrx/store';
import {BasicStateInterface} from '../interfaces/basic-state.interface';


export const selectBasic = (state: StoreStateInterface) => state.basic;

export const selectFirstValue = createSelector(
  selectBasic,
  (state: BasicStateInterface) => state.firstValue
);

export const selectSecondValue = createSelector(
  selectBasic,
  (state: BasicStateInterface) => state.secondValue
);
