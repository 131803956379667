import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';

import {throwIfAlreadyLoaded} from './guards/module-import-guard';
import {CoreStoreModule} from './store/core-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreStoreModule,
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
  ) {
    throwIfAlreadyLoaded(parentModule, CoreModule.name);
  }
}
