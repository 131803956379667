import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {BasicActions, DecreaseAction, IncreaseAction} from '../actions/basic.actions';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class BasicEffects {


  @Effect()
  loadMovies$ = this.actions$
    .pipe(
      ofType(BasicActions.Change),
      switchMap(() => [
        new IncreaseAction(),
        new DecreaseAction(),
        new DecreaseAction()
      ])
    );


  constructor(
    private actions$: Actions,
  ) {
  }

}
