import {Action} from '@ngrx/store';


export enum BasicActions {
  Change = '[Basic] Change',
  Increase = '[Basic] increase',
  Decrease = '[Basic] decrease',
  Reset = '[Basic] reset'
}

export class ChangeAction implements Action {
  readonly type = BasicActions.Change;
}

export class IncreaseAction implements Action {
  readonly type = BasicActions.Increase;
}

export class DecreaseAction implements Action {
  readonly type = BasicActions.Decrease;
}

export class ResetAction implements Action {
  readonly type = BasicActions.Reset;
}

export type ActionsUnion = ChangeAction | IncreaseAction | DecreaseAction | ResetAction;
