import * as Basic from '../actions/basic.actions';
import {BasicActions} from '../actions/basic.actions';
import {BasicStateInterface} from '../interfaces/basic-state.interface';


export const initialState: BasicStateInterface = {
  firstValue: -5,
  secondValue: 10
};


export function basicReducer(state = initialState, action: Basic.ActionsUnion): BasicStateInterface {
  switch (action.type) {

    case BasicActions.Increase: {
      return {
        ...state,
        firstValue: state.firstValue + 1
      };
    }

    case BasicActions.Decrease: {
      return {
        ...state,
        secondValue: state.secondValue - 1
      };
    }

    case BasicActions.Reset: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }

  }
}
